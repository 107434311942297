<template>
  <transition name="fade">
    <div v-if="visible" class="fixed z-10 inset-0 flex items-center justify-center">
      <div class="absolute inset-0 bg-grey-400 opacity-75" @click.stop="$emit('close')" />

      <div class="z-10 w-full sm:w-auto">
        <el-card class="px-16 sm:px-24">
          <div class="flex items-center justify-between">
            <h2 class="text-lg font-medium leading-big">{{ title }}</h2>
            <span
              class="icon-close flex items-center ml-16 pt-1 text-xl text-grey-300 hover:text-grey-400 cursor-pointer"
              @click="$emit('close')"
            />
          </div>

          <slot />
        </el-card>
      </div>
    </div>
  </transition>
</template>

<script>
import { preventBodyScroll } from '@/core/helper-functions'

const ElCard = () => import('@/components/shared/ELCard')

export default {
  name: 'ElModal',

  components: { ElCard },

  props: {
    visible: { type: Boolean, default: false },
    title: { type: String, default: '' }
  },

  watch: {
    visible (newVal) {
      preventBodyScroll(newVal)
    }
  }
}
</script>
